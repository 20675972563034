import { styledComponent, Box, Link } from '@hermes/web-components'

export const StyledBox = styledComponent(Box)(() => ({
  backgroundColor: '#F4F6F6',
  color: '#051851'
}))

export const StyledInfoBox = styledComponent(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.dark2,
  color: '#051851'
}))

export const StyledLink = styledComponent(Link)(({ theme }) => ({
  ...theme.typography.body2,
  textDecoration: 'none',
  color: '#051851'
}))

export const GoogleLogoWrapper = styledComponent(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '60px',
  right: '20px',
  [theme.breakpoints.down('sm')]: {
    bottom: '140px'
  }
}))
