function Button(theme) {
    return {
        styleOverrides: {
            root: {
                backgroundColor: '#2B59E0',
                boxShadow: 'none',
                textTransform: 'none',
                padding: theme.spacing(1, 1.5),
                fontSize: theme.typography.htmlFontSize,
                lineHeight: '24px',
                fontFamily: theme.typography.fontFamily,
                textShadow: '0px 1px 2px rgba(0, 0, 0, 0.04)'
            },
            outlinedPrimary: {
                fontSize: theme.typography.htmlFontSize,
                fontFamily: theme.typography.fontFamily,
                backgroundColor: 'transparent',
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
                '&:hover, &:focus': {
                    backgroundColor: theme.palette.blue[900],
                    color: theme.palette.common.white
                },
                '&:focus': {
                    outline: `1px solid ${theme.palette.primary.dark}`,
                    boxSizing: 'border-box'
                },
                '&:active': {
                    color: theme.palette.common.white,
                    boxShadow: 'none',
                    outline: 'none',
                    backgroundColor: theme.palette.primary.main
                },
                '&:disabled': {
                    borderColor: theme.palette.grey[400],
                    color: theme.palette.grey[500]
                }
            },
            containedPrimary: {
                padding: theme.spacing(1, 3),
                boxShadow: 'none',
                outline: 'none',
                '&:hover': {
                    boxShadow: 'none',
                    outline: 'none',
                    background: 'linear-gradient(90deg, rgba(43, 89, 224, 0.7) 50%, rgba(14, 182, 213, 0.513705) 83.26%, rgba(0, 229, 208, 0.7) 99.99%, rgba(0, 229, 208, 0.7) 100%, rgba(0, 229, 208, 0.42) 100%, rgba(13, 187, 213, 0.7) 100%), #2B59E0'
                },
                '&:focus': {
                    outline: `1px solid ${theme.palette.primary.dark}`,
                    boxSizing: 'border-box'
                },
                '&:active': {
                    boxShadow: 'none',
                    outline: 'none',
                    background: 'linear-gradient(90deg, rgba(17, 41, 113, 0.5) 0%, rgba(43, 89, 224, 0.5) 99.99%, rgba(43, 89, 224, 0.5) 100%), #2B59E0'
                },
                '&:disabled': {
                    backgroundColor: theme.palette.grey[100],
                    color: theme.palette.grey[500]
                }
            }
        },
        variants: [
            {
                props: { variant: 'ghost' },
                style: {
                    backgroundColor: 'transparent',
                    border: '1px solid transparent',
                    color: '#2B59E0'
                }
            }
        ]
    };
}

export { Button as default };
