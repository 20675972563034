import {
  ESKlarheitKurrentBold,
  ESKlarheitKurrentRegular,
  fontFamily,
  PoppinsMedium,
  PoppinsRegular,
  PoppinsSemiBold
} from '../../fonts'
import { theme } from '@hermes/web-components'

const components = {
  MuiCssBaseline: {
    styleOverrides: `
        @font-face ${ESKlarheitKurrentBold}
        @font-face ${ESKlarheitKurrentRegular}
        @font-face ${PoppinsMedium}
        @font-face ${PoppinsSemiBold}
        @font-face ${PoppinsRegular}
        body {
          overflow-x: hidden;
        }
      `
  },
  MuiButtonBase: {
    ...theme.components?.MuiButtonBase,
    styleOverrides: {
      root: {
        fontFamily: `${fontFamily}`,
        fontSize: '15px'
      }
    },
    defaultProps: {
      disableRipple: true
    }
  }
}

export default components
