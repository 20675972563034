import { useMemo } from 'react'
import { TENANTS } from '../constants/global'
import { useRouter } from 'next/router'

export const useWpaTenant = (): { isWPA: boolean } => {
  const { locale } = useRouter()

  const isWPA = useMemo(() => locale === TENANTS.WPA, [locale])

  return { isWPA }
}
