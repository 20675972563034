import getOverrides from './overrides.esm.js';
export { default as getOverrides } from './overrides.esm.js';
import palette from './palette.esm.js';
export { default as palette } from './palette.esm.js';
import typography from './typography.esm.js';
export { default as typography } from './typography.esm.js';
import createTheme from '../node_modules/@mui/material/styles/createTheme.esm.js';

const theme = createTheme({ palette, typography });
theme.components = getOverrides(theme);

export { theme };
