function Rating() {
    return {
        styleOverrides: {
            icon: {
                margin: '0 2px'
            }
        }
    };
}

export { Rating as default };
