import React, { CSSProperties } from 'react'
import { SvgIcon, SvgIconProps } from '@hermes/web-components'

type EndorsementGroupIconProps = SvgIconProps & CSSProperties

const EndorsementGroupIcon = (props: EndorsementGroupIconProps) => (
  <SvgIcon width="37" height="24" viewBox="0 0 37 25" {...props}>
    <circle cx="12" cy="12" r="12" fill="#EAEEFC" />
    <path
      d="M6.61634 15.7831C5.29225 16.0481 4.8273 17.7705 4.76034 18.5986V21.5815C5.70567 22.2982 7.54586 23.9949 12.0828 23.9949C16.6197 23.9949 19.2407 21.6026 19.2407 21.5365V19.0757C19.2407 17.3566 18.3861 16.0082 17.7456 15.7831C17.2333 15.6029 15.0377 14.9131 14.004 14.5907V12.9013C14.4894 12.1416 15.3074 11.061 15.6557 10.6156C16.5726 10.0228 16.1726 9.04372 15.858 8.62825C16.3164 4.69281 13.7024 4.12355 12.128 4.12354C8.5771 4.12354 7.87332 7.12664 8.20457 8.62825C7.59508 9.68824 8.08311 10.3948 8.40331 10.6156C8.40331 11.0661 9.52997 12.3271 10.0933 12.9013V14.5907C9.48601 14.8777 7.94042 15.5181 6.61634 15.7831Z"
      fill="#91A8D6"
    />
    <circle cx="25" cy="12" r="12" fill="#EAEEFC" />
    <path
      d="M19.6163 15.7831C18.2923 16.0481 17.8273 17.7705 17.7603 18.5986V21.5815C18.7057 22.2982 20.5459 23.9949 25.0828 23.9949C29.6197 23.9949 32.2407 21.6026 32.2407 21.5365V19.0757C32.2407 17.3566 31.3861 16.0082 30.7456 15.7831C30.2333 15.6029 28.0377 14.9131 27.004 14.5907V12.9013C27.4894 12.1416 28.3074 11.061 28.6557 10.6156C29.5726 10.0228 29.1726 9.04372 28.858 8.62825C29.3164 4.69281 26.7024 4.12355 25.128 4.12354C21.5771 4.12354 20.8733 7.12664 21.2046 8.62825C20.5951 9.68824 21.0831 10.3948 21.4033 10.6156C21.4033 11.0661 22.53 12.3271 23.0933 12.9013V14.5907C22.486 14.8777 20.9404 15.5181 19.6163 15.7831Z"
      fill="#91A8D6"
    />
  </SvgIcon>
)

export default EndorsementGroupIcon
