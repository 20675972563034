function LinearProgress(theme) {
    return {
        styleOverrides: {
            root: {
                backgroundColor: theme.palette.common.white,
                boxShadow: 'none',
                border: `1px solid ${theme.palette.secondary.dark}`,
                borderRadius: '2px',
                height: '8px'
            },
            bar: {
                background: theme.palette.secondary.main,
                borderRadius: '2px',
                height: '8px'
            }
        }
    };
}

export { LinearProgress as default };
