function AccordionDetails() {
    return {
        styleOverrides: {
            root: {
                padding: '2px 32px'
            }
        }
    };
}

export { AccordionDetails as default };
