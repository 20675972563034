import React__default from 'react';
import SvgIcon from '../../node_modules/@mui/material/SvgIcon/SvgIcon.esm.js';

const UserVerifiedIcon = (props) => (React__default.createElement(SvgIcon, Object.assign({ width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", sx: {
        width: '16px',
        height: '16px'
    } }, props),
    React__default.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7 5.5C7 6.32843 6.32843 7 5.5 7C4.67157 7 4 6.32843 4 5.5C4 4.67157 4.67157 4 5.5 4C6.32843 4 7 4.67157 7 5.5ZM8 5.5C8 6.88071 6.88071 8 5.5 8C4.11929 8 3 6.88071 3 5.5C3 4.11929 4.11929 3 5.5 3C6.88071 3 8 4.11929 8 5.5ZM9.95958 5.62466L9 6.50437L11.7222 9L16 4.88596L15.0404 4.00001L11.7222 7.23434L9.95958 5.62466ZM5.5 9.56876C7.17063 9.56876 9.93125 10.9525 9.93125 11.3125V12.9312H1.06875V11.3125C1.06875 10.9525 3.82937 9.56876 5.5 9.56876ZM5.5 8.50001C3.99813 8.50001 0 9.81625 0 11.3125V14H11V11.3125C11 9.81625 7.00187 8.50001 5.5 8.50001Z", fill: "#5C6B88" })));

export { UserVerifiedIcon as default };
