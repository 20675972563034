import Avatar from './components/Avatar.esm.js';
import AvatarGroup from './components/AvatarGroup.esm.js';
import Button from './components/Button.esm.js';
import Button$1 from './components/IconButton.esm.js';
import Chip from './components/Chip.esm.js';
import AppBar from './components/AppBar.esm.js';
import Badge from './components/Badge.esm.js';
import LinearProgress from './components/LinearProgress.esm.js';
import Tabs from './components/Tabs.esm.js';
import Tab from './components/Tab.esm.js';
import TextField from './components/TextField.esm.js';
import Select from './components/Select.esm.js';
import InputBase from './components/InputBase.esm.js';
import Accordion from './components/Accordion.esm.js';
import AccordionSummary from './components/AccordionSummary.esm.js';
import AccordionDetails from './components/AccordionDetails.esm.js';
import Rating from './components/Rating.esm.js';
import Paper from './components/Paper.esm.js';
import OutlinedInput from './components/OutlinedInput.esm.js';
import ButtonBase from './components/ButtonBase.esm.js';
import MenuItem from './components/MenuItem.esm.js';
import MenuMenuList from './components/MenuList.esm.js';
import PaginationItem from './components/PaginationItem.esm.js';

function getOverrides(theme) {
    return {
        MuiRating: Rating(),
        MuiAccordion: Accordion(),
        MuiAccordionSummary: AccordionSummary(theme),
        MuiAccordionDetails: AccordionDetails(),
        MuiAppBar: AppBar(),
        MuiAvatar: Avatar(),
        MuiButton: Button(theme),
        MuiIconButton: Button$1(theme),
        MuiButtonBase: ButtonBase(theme),
        MuiChip: Chip(theme),
        MuiAvatarGroup: AvatarGroup(),
        MuiBadge: Badge(),
        MuiLinearProgress: LinearProgress(theme),
        MuiTabs: Tabs(theme),
        MuiTab: Tab(theme),
        MuiTextField: TextField(theme),
        MuiSelect: Select(theme),
        MuiInputBase: InputBase(theme),
        MuiPaper: Paper(),
        MuiOutlinedInput: OutlinedInput(theme),
        MuiMenuItem: MenuItem(theme),
        MuiList: MenuMenuList(),
        MuiPaginationItem: PaginationItem(theme)
    };
}

export { getOverrides as default };
