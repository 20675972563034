import { ThemeOptions, deepmerge } from '@hermes/web-components'
import components from './components'
import typography from './typography'
import custom from './custom'
import palette from './palette'
import defaultTheme from '../default'
import { CustomStyles } from './customStyles'

const wpa: ThemeOptions & { custom: CustomStyles } = {
  ...defaultTheme,
  typography: deepmerge(defaultTheme.typography, typography),
  components: deepmerge(defaultTheme.components, components),
  palette: deepmerge(defaultTheme.palette, palette),
  custom
}
export default wpa
