import { CookieBanner, GoogleTagManager, GoogleTagManagerNoScript } from '@hermes/web-components'
import React from 'react'
import { GTM_ID, TENANTS, WPA_GTM_ID } from '../../constants/global'
import { useRouter } from 'next/router'
import DataProtectionBanner from '../DataProtectionBanner'
import getConfigVariable from '../../utils/getConfigVariable'

const IS_PLAY_WRITE_CONTEXT = getConfigVariable('IS_PLAY_WRITE_CONTEXT')

const Analytics = () => {
  const router = useRouter()
  const isWPATenant = router.locale === TENANTS.WPA
  const gtmId = isWPATenant ? WPA_GTM_ID : GTM_ID

  if (IS_PLAY_WRITE_CONTEXT) {
    return null
  }

  return (
    <>
      <CookieBanner isWPA={isWPATenant} />
      <GoogleTagManager gtmId={gtmId} />
      {isWPATenant ? <GoogleTagManagerNoScript gtmId={gtmId} /> : null}
      {isWPATenant && <DataProtectionBanner />}
    </>
  )
}

export default Analytics
