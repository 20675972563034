function Avatar() {
    return {
        defaultProps: {
            variant: 'square'
        },
        styleOverrides: {
            root: {},
            square: {
                borderRadius: 2
            }
        }
    };
}

export { Avatar as default };
