function Chip(theme) {
    var _a, _b, _c, _d;
    return {
        defaultProps: {
            size: 'small'
        },
        styleOverrides: {
            root: {
                boxShadow: 'none',
                borderRadius: 16,
                background: theme.palette.grey[100],
                padding: theme.spacing(0.25, 0.5),
                fontSize: theme.typography.body2.fontSize,
                color: theme.palette.text.disabled
            },
            outlined: {
                backgroundColor: 'transparent'
            }
        },
        variants: [
            {
                props: { variant: 'option' },
                style: {
                    fontSize: (_b = (_a = theme.typography) === null || _a === void 0 ? void 0 : _a.body2) === null || _b === void 0 ? void 0 : _b.fontSize,
                    fontFamily: theme.typography.fontFamily,
                    backgroundColor: theme.palette.secondary.light,
                    color: theme.palette.primary.main,
                    border: '1px solid transparent',
                    fontWeight: 500,
                    '&:hover': {
                        backgroundColor: theme.palette.secondary.light,
                        border: `1px ${theme.palette.primary.main} solid`
                    },
                    '&.Mui-disabled': {
                        backgroundColor: theme.palette.grey[100],
                        opacity: 1,
                        color: theme.palette.text.disabled
                    }
                }
            },
            {
                props: { variant: 'selected' },
                style: {
                    fontSize: (_d = (_c = theme.typography) === null || _c === void 0 ? void 0 : _c.body2) === null || _d === void 0 ? void 0 : _d.fontSize,
                    fontFamily: theme.typography.fontFamily,
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                    fontWeight: 500,
                    '&:hover': {
                        backgroundColor: theme.palette.primary.main,
                        border: 0
                    }
                }
            },
            {
                props: { variant: 'aqua' },
                style: {
                    backgroundColor: theme.palette.system.aqua200,
                    borderColor: theme.palette.secondary.light,
                    '&:hover': {
                        backgroundColor: theme.palette.system.aqua400,
                        borderColor: theme.palette.system.aqua800
                    },
                    '&:focus': {
                        backgroundColor: theme.palette.system.aqua400,
                        borderColor: theme.palette.system.aqua800
                    }
                }
            },
            {
                props: { variant: 'coral' },
                style: {
                    backgroundColor: theme.palette.system.coral200,
                    borderColor: theme.palette.secondary.light,
                    '&:hover': {
                        backgroundColor: theme.palette.system.coral400,
                        borderColor: theme.palette.system.coral800
                    },
                    '&:focus': {
                        backgroundColor: theme.palette.system.coral400,
                        borderColor: theme.palette.system.coral800
                    }
                }
            },
            {
                props: { variant: 'lightBlue' },
                style: {
                    backgroundColor: theme.palette.system.lightBlue200,
                    borderColor: theme.palette.secondary.light,
                    '&:hover': {
                        backgroundColor: theme.palette.system.lightBlue400,
                        borderColor: theme.palette.system.lightBlue800
                    },
                    '&:focus': {
                        backgroundColor: theme.palette.system.lightBlue400,
                        borderColor: theme.palette.system.lightBlue800
                    }
                }
            },
            {
                props: { variant: 'blue' },
                style: {
                    backgroundColor: theme.palette.secondary.light,
                    borderColor: theme.palette.background.default,
                    '&:hover': {
                        backgroundColor: theme.palette.blue[200],
                        borderColor: theme.palette.system.navy800
                    },
                    '&:focus': {
                        backgroundColor: theme.palette.secondary.light,
                        borderColor: theme.palette.text.darkNavy800
                    }
                }
            },
            {
                props: { variant: 'blue200' },
                style: {
                    backgroundColor: theme.palette.blue[200],
                    color: theme.palette.text.darkNavy,
                    '&:hover': {
                        backgroundColor: theme.palette.blue[300]
                    }
                }
            },
            {
                props: { variant: 'blue300' },
                style: {
                    backgroundColor: theme.palette.blue[300],
                    border: `1px solid ${theme.palette.secondary.light}`,
                    color: theme.palette.text.dark
                }
            },
            {
                props: { variant: 'blue800' },
                style: {
                    backgroundColor: theme.palette.blue[800],
                    color: theme.palette.common.white,
                    '&:hover': {
                        backgroundColor: theme.palette.system.hoverBlue
                    }
                }
            },
            {
                props: { variant: 'aqua800' },
                style: {
                    backgroundColor: theme.palette.system.aqua800,
                    color: theme.palette.text.darkNavy,
                    '&:hover': {
                        backgroundColor: theme.palette.system.aqua900
                    }
                }
            },
            {
                props: { variant: 'coralPrimary' },
                style: {
                    backgroundColor: theme.palette.system.coral,
                    color: theme.palette.text.darkNavy,
                    '&:hover': {
                        backgroundColor: theme.palette.system.coral900
                    }
                }
            }
        ]
    };
}

export { Chip as default };
