function MenuMenuList() {
    return {
        styleOverrides: {
            root: {
                padding: 0
            }
        }
    };
}

export { MenuMenuList as default };
