const newPersistentPanelEnabled = true
const alternativeSearchEnabled = true

export const getFeatureFlags = () => {
  return {
    newPersistentPanelEnabled,
    alternativeSearchEnabled
  }
}

export const useFeatureFlags = getFeatureFlags
