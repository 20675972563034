function InputBase(theme) {
    return {
        styleOverrides: {
            root: {
                fontSize: '13.33px',
                fontFamily: theme.typography.fontFamily,
                '&:before': {
                    display: 'none'
                },
                '& > input::placeholder': {
                    color: theme.palette.text.disabled,
                    opacity: 1,
                    fontSize: '13.33px',
                    fontWeight: 400
                },
                '&:hover, &:active, &:focus': {
                    borderColor: theme.palette.primary.main,
                    outline: 'none'
                }
            }
        }
    };
}

export { InputBase as default };
