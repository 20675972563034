import { createTheme, Theme } from '@hermes/web-components'
import defaultTheme from './tenants/default'
import wpaTheme from './tenants/wpa'
import { RTL_TENANTS, TENANTS } from '../../constants/global'

const getTheme = (locale?: string): Theme => {
  const isRtl = !!locale && RTL_TENANTS.includes(locale)

  const theme = defaultTheme

  // potential to extend
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (locale) {
    case TENANTS.WPA: {
      return createTheme(wpaTheme)
    }
    default: {
      return createTheme({
        ...theme,
        direction: isRtl ? 'rtl' : undefined
      })
    }
  }
}

export default getTheme
