const typography = {
    fontFamily: 'Poppins, sans-serif',
    htmlFontSize: 15,
    h1: {
        fontSize: '61.65px',
        fontWeight: 900
    },
    h2: {
        fontSize: '27.03px',
        fontWeight: 700
    },
    h3: {
        fontSize: '21.36px',
        fontWeight: 200
    },
    h4: {
        fontSize: '16.88px',
        fontWeight: 500
    },
    body1: {
        fontSize: '15px'
    },
    body2: {
        fontSize: '13.33px'
    },
    caption: {
        fontSize: '11.85px'
    },
    subtitle1: {
        fontSize: '16px'
    }
};

export { typography as default };
