function ButtonBase(theme) {
    return {
        styleOverrides: {
            root: {
                boxShadow: 'none',
                textTransform: 'none',
                fontSize: theme.typography.htmlFontSize,
                lineHeight: '24px',
                fontFamily: theme.typography.fontFamily,
                textShadow: '0px 1px 2px rgba(0, 0, 0, 0.04)'
            }
        }
    };
}

export { ButtonBase as default };
