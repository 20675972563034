function OutlinedInput(theme) {
    return {
        styleOverrides: {
            root: {
                outline: 'none',
                borderWidth: 1,
                '&:hover .MuiOutlinedInput-notchedOutline, &:active .MuiOutlinedInput-notchedOutline, &:focus .MuiOutlinedInput-notchedOutline, &:focus-visible .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.primary.main,
                    outline: 'none',
                    borderWidth: 1
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderWidth: 1
                },
                '& .Mui-focused': {
                    '.MuiOutlinedInput-notchedOutline': {
                        borderWidth: 1
                    }
                }
            },
            notchedOutline: {
                borderWidth: 1,
                outline: 'none'
            }
        }
    };
}

export { OutlinedInput as default };
