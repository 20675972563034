function Badge() {
    return {
        styleOverrides: {
            badge: {
                backgroundColor: 'red'
            }
        }
    };
}

export { Badge as default };
