import { __rest } from '../../_virtual/_tslib.esm.js';
import React__default from 'react';
import SvgIcon from '../../node_modules/@mui/material/SvgIcon/SvgIcon.esm.js';

const ManIcon = (_a) => {
    var { color } = _a, props = __rest(_a, ["color"]);
    return (React__default.createElement(SvgIcon, Object.assign({ width: "20", height: "20", viewBox: "0 0 20 20" }, props),
        React__default.createElement("path", { d: "M16.6666 17.5V15.8333C16.6666 14.9493 16.3154 14.1014 15.6903 13.4763C15.0652 12.8512 14.2173 12.5 13.3333 12.5H6.66659C5.78253 12.5 4.93468 12.8512 4.30956 13.4763C3.68444 14.1014 3.33325 14.9493 3.33325 15.8333V17.5", stroke: color, strokeWidth: "1.2", strokeLinecap: "round", strokeLinejoin: "round", fill: "none" }),
        React__default.createElement("path", { d: "M10.0007 9.5C11.6576 9.5 13.0007 8.15685 13.0007 6.5C13.0007 4.84315 11.6576 3.5 10.0007 3.5C8.34388 3.5 7.00073 4.84315 7.00073 6.5C7.00073 8.15685 8.34388 9.5 10.0007 9.5Z", stroke: color, strokeWidth: "1.2", strokeLinecap: "round", strokeLinejoin: "round", fill: "none" })));
};

export { ManIcon };
