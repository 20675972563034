import { __rest } from '../../_virtual/_tslib.esm.js';
import React__default from 'react';
import SvgIcon from '../../node_modules/@mui/material/SvgIcon/SvgIcon.esm.js';

const UnverifiedIcon = (_a) => {
    var { color, width, height } = _a, props = __rest(_a, ["color", "width", "height"]);
    return (React__default.createElement(SvgIcon, Object.assign({ width: width || '24', height: height || '24', viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React__default.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6 7L12 5L18 7V11.2727C18 14.3 15.08 18.1309 12 19C7.92 18.1309 6 14.3 6 11.2727V7ZM14.0408 9L12 11.0408L9.95918 9L9 9.95918L11.0408 12L9 14.0408L9.95919 15L12 12.9592L14.0408 15L15 14.0408L12.9592 12L15 9.95919L14.0408 9Z" })));
};

export { UnverifiedIcon as default };
