function AvatarGroup() {
    return {
        defaultProps: {
            variant: 'square'
        },
        styleOverrides: {
            avatar: {
                root: {
                    backgroundColor: 'lightgray'
                },
                square: {
                    borderRadius: 2
                }
            }
        }
    };
}

export { AvatarGroup as default };
