import default_1 from '../../node_modules/@mui/icons-material/ExpandMore.esm.js';

function Select(theme) {
    return {
        defaultProps: {
            variant: 'standard',
            IconComponent: default_1
        },
        styleOverrides: {
            root: {
                '&:before, &:after': {
                    display: 'none'
                }
            },
            standard: {
                color: theme.palette.primary.dark,
                fontSize: theme.typography.body2.fontSize,
                border: 'none',
                '&:before, &:after': {
                    display: 'none'
                }
            },
            icon: {
                fontSize: '1.2rem',
                color: 'inherit',
                height: '1rem',
                widht: '1rem'
            }
        }
    };
}

export { Select as default };
