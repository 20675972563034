import React from 'react'
import { Box, Grid, TwitterIcon, FacebookIcon, InstagramIcon, LinkedInIcon, Link } from '@hermes/web-components'
import { FooterSocialTypes, SocialListProps } from '../../types'

const iconStyle = () => ({
  width: '24px',
  height: '24px',
  color: '#051851'
})

const getSocialIcon = (type: FooterSocialTypes) => {
  switch (type) {
    case FooterSocialTypes.TWITTER:
      return <TwitterIcon sx={iconStyle} />

    case FooterSocialTypes.FACEBOOK:
      return <FacebookIcon sx={iconStyle} />

    case FooterSocialTypes.INSTAGRAM:
      return <InstagramIcon sx={iconStyle} />

    case FooterSocialTypes.LINKEDIN:
      return <LinkedInIcon sx={iconStyle} />

    default:
      return <></>
  }
}

interface Props {
  lang: string
  socialList?: SocialListProps[]
}

const Socials = ({ lang, socialList = [] }: Props) => (
  <Box>
    <Grid container spacing={1} sx={{ width: '100%', display: 'flex', gap: '31px' }}>
      {socialList.map(({ href, type }) => (
        <Grid key={`grid-item-${type}`} item>
          <Link href={href[lang]} target="_blank">
            {getSocialIcon(type)}
          </Link>
        </Grid>
      ))}
    </Grid>
  </Box>
)

export default Socials
