import { createContext, useState, PropsWithChildren, useEffect, useMemo } from 'react'
import { getCookie } from '../../utils/cookie'
import getTenantName from '../../utils/getTenantName'
import { AppDataState, AppDataProps } from './types'
import { RTL_TENANTS } from '../../constants/global'

const AppDataContext = createContext<AppDataState>({
  locale: '',
  language: '',
  userName: null,
  isAuthorized: false,
  asPath: '',
  setLocale: () => {}
})

function AppDataProvider({ locale, language, tenantSettings, children, asPath }: PropsWithChildren<AppDataProps>) {
  const isRtl = useMemo(() => RTL_TENANTS.includes(locale), [locale])
  const [state, setState] = useState<AppDataState>({
    userName: null,
    locale,
    language,
    tenantSettings,
    asPath,
    isAuthorized: false,
    isRtl,
    setLocale: (newLocale) => setState((prevState) => ({ ...prevState, locale: newLocale }))
  })

  useEffect(() => {
    const identityCookieName = `${getTenantName(locale)}_identity`
    const userName = getCookie(document.cookie, identityCookieName)

    setState((currentState) => ({ ...currentState, isAuthorized: !!userName }))

    if (userName) {
      setState((currentState) => ({ ...currentState, userName }))
    }
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <AppDataContext.Provider value={state}>{children}</AppDataContext.Provider>
}

export { AppDataProvider, AppDataContext }
