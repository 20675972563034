/*
 * Note: This function was created in order to restore links after Next.js rewrites,
 * they don't work well because after rewrite the current path on Server-Side comes invalid.
 * For example, the user is on the link  -/uk/find/all/scotland/hospitals#location=Scotland
 * But receives in router.asPath - /uk/find/all/scotland/hospital?first=scotland&location=Scotland
 */

const rewrites = [
  {
    pattern: /^\/?(\w{2,5}(-\w{2,5})?\/)?find\/all\/(.*)\/hospital\b(.*)?$/,
    replacement: '/$1find/all/$3/hospitals$4'
  },
  {
    pattern: /^\/?(\w{2,5}(-\w{2,5})?\/)?find\/all\/(.*)\/pharmacy\b(.*)?$/,
    replacement: '/$1find/all/$3/pharmacies$4'
  },
  {
    pattern: /^\/?(\w{2,5}(-\w{2,5})?\/)?find\/all\/(.*)\/homecare\b(.*)?$/,
    replacement: '/$1find/all/$3/home-carers$4'
  },
  {
    pattern: /^\/?(\w{2,5}(-\w{2,5})?\/)?find\/all\/(.*)\/care-home\b(.*)?$/,
    replacement: '/$1find/all/$3/care-homes$4'
  }
]

const transformUrl = (url: string) => {
  const [base, queryString] = url.split('?')

  if (!queryString) {
    return url
  }

  const params = new URLSearchParams(queryString)

  params.delete('first')
  params.delete('second')

  return params.toString() ? `${base}#${params.toString()}` : base
}

const buildAsPath = (path: string) => {
  const asPath = transformUrl(path)
  const [pathname, hash] = asPath.split('#')

  for (const { pattern, replacement } of rewrites) {
    if (pattern.test(pathname)) {
      const rewrittenPath = pathname.replace(pattern, replacement).replace('//', '/')
      return hash ? `${rewrittenPath}#${hash}` : rewrittenPath
    }
  }

  return asPath
}

export default buildAsPath
