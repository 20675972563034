import { __rest } from '../../_virtual/_tslib.esm.js';
import React__default from 'react';
import SvgIcon from '../../node_modules/@mui/material/SvgIcon/SvgIcon.esm.js';

const VerifiedCircleIcon = (_a) => {
    var { color, fill, width, height } = _a, props = __rest(_a, ["color", "fill", "width", "height"]);
    return (React__default.createElement(SvgIcon, Object.assign({ width: width || '32', height: height || '32', viewBox: "0 0 32 32" }, props),
        React__default.createElement("path", { d: "M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z", fill: "#112971" }),
        React__default.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8 9.33333L16 6.66667L24 9.33333V15.0303C24 19.0667 20.1067 24.1745 16 25.3333C10.56 24.1745 8 19.0667 8 15.0303V9.33333ZM10.6667 16.603L11.92 15.4055L14.2222 17.5966L20.08 12L21.3333 13.2059L14.2222 20L10.6667 16.603Z", fill: "white" })));
};

export { VerifiedCircleIcon as default };
