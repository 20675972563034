function Accordion() {
    return {
        styleOverrides: {
            root: {
                marginBottom: '2px',
                boxShadow: 'none',
                '&.Mui-expanded': {
                    margin: 0,
                    marginBottom: '2px'
                },
                '&:before': {
                    display: 'none'
                }
            }
        }
    };
}

export { Accordion as default };
