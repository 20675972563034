import React, { PropsWithChildren, useContext } from 'react'
import { AppDataContext } from '../providers/AppData'
import Header from './Header'
import Footer from './Footer'
import { useRouter } from 'next/router'
import { useWpaTenant } from '../hooks/useWpaTenant'
import WpaHeader from './WpaHeader'
import WpaFooter from './WpaFooter'
import { CLAIM_YOUR_PROFILE_WPA_ROUTE } from '../constants/global'

const useComponentConfig = () => {
  const { isWPA } = useWpaTenant()
  const { pathname } = useRouter()

  const isWPAClaimYourProfile = pathname.includes(CLAIM_YOUR_PROFILE_WPA_ROUTE)

  const isLightHeaderVariant = isWPAClaimYourProfile
  const showWPAParts = isWPA || isWPAClaimYourProfile

  return { showWPAParts, isLightHeaderVariant }
}

function AppWrapper({ children }: PropsWithChildren<object>) {
  const { pathname } = useRouter()
  const { showWPAParts, isLightHeaderVariant } = useComponentConfig()
  const { tenantSettings, locale, language } = useContext(AppDataContext)

  return (
    <>
      {showWPAParts ? <WpaHeader shouldReverse={isLightHeaderVariant} logoAlign="flex-start" /> : <Header />}
      {children}
      {showWPAParts ? (
        <WpaFooter
          locale={locale}
          lang={language}
          footerColumnLinks={tenantSettings?.footerColumnLinks}
          footerSocialLinks={tenantSettings?.footerSocialLinks}
          pathname={pathname}
        />
      ) : (
        <Footer
          locale={locale}
          lang={language}
          footerColumnLinks={tenantSettings?.footerColumnLinks}
          footerSocialLinks={tenantSettings?.footerSocialLinks}
          pathname={pathname}
        />
      )}
    </>
  )
}

export default AppWrapper
