import { fontFamily, fontFamilyESKlarheitKurrent } from '../../fonts'
import { theme } from '@hermes/web-components'

const typography = {
  htmlFontSize: 15,
  fontFamily,
  h1: {
    fontSize: '61.65px',
    fontWeight: 900,
    fontFamily: fontFamilyESKlarheitKurrent,
    [theme.breakpoints.down('lg')]: {
      fontSize: '50px'
    }
  },
  h2: {
    fontSize: '27.03px',
    fontWeight: 200,
    fontFamily: fontFamilyESKlarheitKurrent
  },
  h3: {
    fontSize: '21.36px',
    fontWeight: 200,
    fontFamily: fontFamilyESKlarheitKurrent
  },
  h4: {
    fontSize: '16.88px',
    fontWeight: 500,
    fontFamily
  },
  body1: {
    fontSize: '14px',
    fontFamily
  },
  body2: {
    fontSize: '13.33px',
    fontFamily
  },
  caption: {
    fontSize: '11.85px',
    fontFamily
  },
  subtitle2: {
    fontSize: '10.53px',
    fontFamily
  },
  locationCard: {
    lineHeight: '16px'
  }
}

export default typography
