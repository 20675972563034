const palette = {
  background: {
    keywordPrimary: 'rgba(0, 229, 208, 0.2)',
    keywordSecondary: '#EAEEFC',
    keywordOther: 'rgba(255, 99, 92, 0.2)'
  },
  titleColor: {
    color: '#051851'
  }
}

export default palette
