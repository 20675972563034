import { ProfileKeyword, ProfileKeywordRelated } from '../../types/keywords'
import { PracticeProfile } from '../../types/practice'
import { Location } from '../../types/index'
import { SpecialistProfile } from '../../types/specialist'

export enum AutocompleteSearchTypes {
  practice = 'practice',
  specialist = 'specialist'
}

export enum AlternativeNavigationSearchTypes {
  practices = 'practices',
  specialists = 'specialists',
  dentist = 'dentistry',
  careHome = 'care-homes',
  healthAndSocialCare = 'home-carers',
  hospital = 'hospitals',
  pharmacy = 'pharmacies'
}

export enum SearchTypes {
  practices = 'practices',
  specialists = 'specialists',
  dentist = 'dentistry',
  careHome = 'care-home',
  healthAndSocialCare = 'homecare',
  hospital = 'hospital',
  pharmacy = 'pharmacy'
}

export enum ProfileQueryTypes {
  dentist = SearchTypes.dentist,
  careHome = SearchTypes.careHome,
  healthAndSocialCare = SearchTypes.healthAndSocialCare,
  hospital = SearchTypes.hospital,
  pharmacy = SearchTypes.pharmacy
}

export enum ProfileTypes {
  practices = SearchTypes.practices,
  specialists = SearchTypes.specialists,
  dentist = SearchTypes.dentist,
  careHome = SearchTypes.careHome,
  healthAndSocialCare = SearchTypes.healthAndSocialCare,
  hospital = SearchTypes.hospital,
  pharmacy = SearchTypes.pharmacy
}

export enum SortType {
  relevance = '',
  nearest = 'nearest',
  rating = 'rating',
  recommended = 'recommended'
}

export enum SearchQueries {
  searchString = 'searchString',
  insurer = 'insurer',
  distance = 'distance',
  specialistType = 'specialistType',
  appointmentType = 'appointmentType',
  gender = 'gender',
  language = 'language',
  locationId = 'locationId',
  typeOfSpecialist = 'typeOfSpecialist',
  profileType = 'profileType',
  specialties = 'specialties',
  sortType = 'sortType',
  location = 'location',
  treatment = 'treatment'
}

export type SearchQueriesKeys = (keyof typeof SearchQueries)[]

export interface SearchProfilesPageProviderFilters {
  searchType?: SearchTypes
  profileType: ProfileTypes
  searchString: string
  specialties: string[]
  selectedKeyword: string
  specialistType: string
  distance: string | number
  appointmentType: string
  sortType: SortType
  gender: string
  language: string
  insurer: string
  page: number
  location: string
  locationId: string
  childLocations?: Location[]
  breadcrumb?: { label: string; url: string }[]
  lat: string
  lon: string
  typeOfSpecialist: string
  treatment: string
  parentLocation?: string
  extraSpecialties?: string[]
  type?: string
  urlKeyword: string
  patientsChildren?: boolean
  feeAgreed?: boolean
  premium?: boolean
  slots?: boolean
}

export interface BuildUrlParams {
  keywordSlug?: string
  procedureConditionSlug?: string | null
  lat?: number
  lon?: number
  slug?: string | null
  location?: string
  distance?: string | number
  profileType?: ProfileTypes | SearchTypes
  appointmentType?: string
  typeOfSpecialist?: string
  gender?: string
  language?: string
  insurer?: string
  page?: number
  sortType?: string
  premium?: boolean
  feeAgreed?: boolean
  patientsChildren?: boolean
  slots?: boolean
  resetSearch?: boolean
}

export interface SearchProfilesPageProviderState {
  state: {
    currentLocation: Location
    practices: PracticeProfile[]
    specialists: SpecialistProfile[]
    specialistsSponsors: SpecialistProfile[] | undefined
    practiceSponsors: PracticeProfile[] | undefined
    practicesAutocomplete: Pick<PracticeProfile, 'id' | 'images' | 'name' | 'slug'>[]
    specialistsAutocomplete: Pick<SpecialistProfile, 'id' | 'images' | 'firstName' | 'lastName' | 'slug' | 'keywords'>[]
    specialties: Pick<ProfileKeyword, 'id' | 'iconUri' | 'name' | 'slug' | 'type'>[]
    conditionsProcedures: Pick<ProfileKeyword, 'id' | 'iconUri' | 'name' | 'slug' | 'type'>[]
    locations: Location[]
    typeOfSpecialist: ProfileKeywordRelated[]
    relatedKeywords: ProfileKeywordRelated[]
    filters: SearchProfilesPageProviderFilters
    specialty: ProfileKeyword
    isLoading: boolean
    isKeywordChanged: boolean
    isLocationChanged: boolean
    isTypeChanged: boolean
    isEmptyResponse: boolean
    isMainKeywordConditionOrProcedure: boolean
    totalProfiles: number
    defaultLocation: Location
    clientReloaded: boolean
  }
  searchKeywordTitle: string
  resetLocationsAutocomplete: () => void
  buildUrl: (props: BuildUrlParams) => string
  changeFilter: (key: string, value: string | number | boolean | string[]) => void
  changeKeyword: () => void
  changeType: () => void
  changeLocation: () => void
  changeSearch: (search: string) => void
  changeLocationCoordinates: (lat: string, lon: string, location?: string) => void
  changeLocationSearch: (search: string, language: string) => void
  changeSpecialty: (id: string, slug: string, procedureOrCondition?: boolean, selectedKeyword?: string) => void
  changeTypeOfSpecialist: (id: string) => void
  changePage: (page: number) => void
  changeSearchType: (searchType: SearchTypes) => void
  changeCurrentLocation: (locationId: string, locationName?: string, slug?: string | null) => void
  openProfilePage: (slug: string) => void
  reloadData: () => void
  loadCurrentLocationByLatLon: (lat: string, lon: string) => Promise<void>
}

export interface SearchProfilesPageProviderProps {
  specialists?: SpecialistProfile[]
  practices?: PracticeProfile[]
  specialistsSponsors?: SpecialistProfile[]
  practiceSponsors?: PracticeProfile[]
  typeOfSpecialist: ProfileKeywordRelated[]
  relatedKeywords: ProfileKeywordRelated[]
  defaultFilters: SearchProfilesPageProviderFilters
  totalProfiles: number
  isMainKeywordConditionOrProcedure: boolean
  specialty: ProfileKeyword
  breadcrumb: { label: string; url: string }[]
  conditionsAndProcedures: ProfileKeyword[]
  specialties: ProfileKeyword[]
  locationsAutocomplete: Location[]
  currentLocation: Location
  distance: string
  defaultLocation: Location
}
