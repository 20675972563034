import { __rest } from '../../_virtual/_tslib.esm.js';
import React__default, { useState, useRef, useEffect, useMemo } from 'react';
import { InView } from '../../node_modules/react-intersection-observer/index.esm.js';
import styled from '../../node_modules/@mui/system/esm/styled.esm.js';
import Box from '../../node_modules/@mui/material/Box/Box.esm.js';

const StyledImage = styled('img', {
    shouldForwardProp: (prop) => prop !== 'sx'
})(({ sx }) => (Object.assign({}, sx)));
function Image(_a) {
    var { lazy, src, errorSrc, lazyOffset, alt, height, width, customOnImageError, forceShow, className, sx = {} } = _a, imageAttributes = __rest(_a, ["lazy", "src", "errorSrc", "lazyOffset", "alt", "height", "width", "customOnImageError", "forceShow", "className", "sx"]);
    const [primaryImageUrlNotLoaded, setPrimaryImageUrlNotLoaded] = useState(false);
    const imageRef = useRef(null);
    useEffect(() => {
        const image = imageRef.current;
        if (image && image.complete && image.naturalWidth === 0) {
            customOnImageError === null || customOnImageError === void 0 ? void 0 : customOnImageError();
            setPrimaryImageUrlNotLoaded(true);
        }
    }, []);
    const onLoadImageError = () => {
        customOnImageError === null || customOnImageError === void 0 ? void 0 : customOnImageError();
        setPrimaryImageUrlNotLoaded(true);
    };
    const srcToShow = useMemo(() => {
        if (primaryImageUrlNotLoaded && errorSrc) {
            return errorSrc;
        }
        return src;
    }, [primaryImageUrlNotLoaded, errorSrc, src]);
    if (!src && !forceShow) {
        return null;
    }
    return (React__default.createElement(InView, { rootMargin: `${lazyOffset}px`, triggerOnce: true, skip: !lazy, initialInView: !lazy }, ({ inView, ref }) => (React__default.createElement(Box, { ref: ref }, inView && (React__default.createElement(StyledImage, Object.assign({}, imageAttributes, { sx: sx, className: className, ref: imageRef, src: srcToShow, key: srcToShow, alt: alt, width: width, height: height, onError: onLoadImageError })))))));
}
Image.defaultProps = {
    lazy: false,
    lazyOffset: 100,
    errorSrc: '',
    height: undefined,
    width: undefined
};

export { Image as default };
