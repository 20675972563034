import { __rest } from '../../_virtual/_tslib.esm.js';
import React__default from 'react';
import SvgIcon from '../../node_modules/@mui/material/SvgIcon/SvgIcon.esm.js';

const Users = (_a) => {
    var { color } = _a, props = __rest(_a, ["color"]);
    return (React__default.createElement(SvgIcon, Object.assign({ width: "16", height: "16", viewBox: "0 0 16 16" }, props),
        React__default.createElement("path", { d: "M11.3332 14V12.6667C11.3332 11.9594 11.0522 11.2811 10.5521 10.781C10.052 10.281 9.37375 10 8.6665 10H3.33317C2.62593 10 1.94765 10.281 1.44755 10.781C0.947456 11.2811 0.666504 11.9594 0.666504 12.6667V14", stroke: color || '#11245A', strokeWidth: "1.2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React__default.createElement("path", { d: "M6.00016 7.33333C7.47292 7.33333 8.66683 6.13943 8.66683 4.66667C8.66683 3.19391 7.47292 2 6.00016 2C4.5274 2 3.3335 3.19391 3.3335 4.66667C3.3335 6.13943 4.5274 7.33333 6.00016 7.33333Z", stroke: color || '#11245A', strokeWidth: "1.2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React__default.createElement("path", { d: "M15.3335 14V12.6667C15.3331 12.0758 15.1364 11.5019 14.7744 11.0349C14.4124 10.5679 13.9056 10.2344 13.3335 10.0867", stroke: color || '#11245A', strokeWidth: "1.2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React__default.createElement("path", { d: "M10.6665 2.08667C11.2401 2.23354 11.7485 2.56714 12.1116 3.03488C12.4747 3.50262 12.6717 4.07789 12.6717 4.67C12.6717 5.26212 12.4747 5.83739 12.1116 6.30513C11.7485 6.77287 11.2401 7.10647 10.6665 7.25334", stroke: color || '#11245A', strokeWidth: "1.2", strokeLinecap: "round", strokeLinejoin: "round" })));
};

export { Users as default };
