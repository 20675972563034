import { __rest } from '../../_virtual/_tslib.esm.js';
import React__default from 'react';
import SvgIcon from '../../node_modules/@mui/material/SvgIcon/SvgIcon.esm.js';

const Clock = (_a) => {
    var { color } = _a, props = __rest(_a, ["color"]);
    return (React__default.createElement(SvgIcon, Object.assign({ width: "14", height: "14", viewBox: "0 0 14 14" }, props),
        React__default.createElement("path", { d: "M7.021 3.03947V7.3307L9.16425 8.92987", stroke: color || '#11245A', strokeLinecap: "round", strokeLinejoin: "round" }),
        React__default.createElement("circle", { cx: "7", cy: "7", r: "6", stroke: color || '#11245A', strokeWidth: "1.2" })));
};

export { Clock as default };
