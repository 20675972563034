import NextImage from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useContext, useMemo } from 'react'
import { TEST_IDS } from '../../__tests__/testUtils/constants'
import { AppDataContext } from '../../providers/AppData'
import { CLAIM_YOUR_PROFILE_WPA_ROUTE } from '../../constants/global'
import { WpaHeaderContainer } from './styles'

export type WpaHeaderProps = {
  shouldReverse?: boolean
  logoAlign?: 'flex-end' | 'flex-start' | 'center'
}

const WpaHeader: React.FC<WpaHeaderProps> = ({ shouldReverse, logoAlign = 'flex-start' }) => {
  const router = useRouter()
  const { locale } = useContext(AppDataContext)

  const isWPAClaimYourProfile = router.pathname.includes(CLAIM_YOUR_PROFILE_WPA_ROUTE)

  const imageSrc = useMemo(() => `/hermes-static/wpa-logo-${shouldReverse ? 'navy' : 'light'}.svg`, [shouldReverse])

  const modifiedLocale = router.locale === 'ar-ae' ? 'en-ae' : locale

  return (
    <WpaHeaderContainer
      container
      data-testid={TEST_IDS.HEADER_FIND_PAGE}
      alignItems="center"
      alignContent="center"
      shouldReverse={shouldReverse}
      logoAlign={logoAlign}
    >
      <Link href="/" locale={isWPAClaimYourProfile ? 'uk' : modifiedLocale} passHref>
        <NextImage src={imageSrc} alt="Doctify logo" width={400} height={40} priority style={{ cursor: 'pointer' }} />
      </Link>
    </WpaHeaderContainer>
  )
}

export default WpaHeader
