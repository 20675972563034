function Tabs(theme) {
    return {
        styleOverrides: {
            root: {
                backgroundColor: theme.palette.primary.main,
                textTransform: 'lowercase'
            }
        }
    };
}

export { Tabs as default };
