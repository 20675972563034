function Button(theme) {
    return {
        styleOverrides: {
            root: {
                backgroundColor: theme.palette.primary.main,
                '&:hover, &:focus': {
                    background: 'linear-gradient(90deg, rgba(43, 89, 224, 0.7) 50%, rgba(14, 182, 213, 0.513705) 83.26%, rgba(0, 229, 208, 0.7) 99.99%, rgba(0, 229, 208, 0.7) 100%, rgba(0, 229, 208, 0.42) 100%, rgba(13, 187, 213, 0.7) 100%), #2B59E0;'
                }
            }
        }
    };
}

export { Button as default };
