function TextField(theme) {
    return {
        styleOverrides: {
            root: {
                backgroundColor: theme.palette.common.white
            }
        }
    };
}

export { TextField as default };
