import { __rest } from '../../_virtual/_tslib.esm.js';
import React__default from 'react';
import SvgIcon from '../../node_modules/@mui/material/SvgIcon/SvgIcon.esm.js';

const Rosette = (_a) => {
    var { color } = _a, props = __rest(_a, ["color"]);
    return (React__default.createElement(SvgIcon, Object.assign({ width: "18", height: "18", viewBox: "0 0 18 18" }, props),
        React__default.createElement("path", { d: "M13.8152 6.12118C13.8152 8.61951 11.7899 10.6448 9.29159 10.6448C6.79326 10.6448 4.76797 8.61951 4.76797 6.12118C4.76797 3.62285 6.79326 1.59756 9.29159 1.59756C11.7899 1.59756 13.8152 3.62285 13.8152 6.12118Z", stroke: color || '#11245A', strokeWidth: "1.2" }),
        React__default.createElement("path", { d: "M6.49707 9.66042V15.3567C6.49707 15.7927 7.01635 16.0198 7.33653 15.7238L8.95203 14.23C9.14365 14.0528 9.43932 14.0528 9.63095 14.23L11.2464 15.7238C11.5666 16.0198 12.0859 15.7927 12.0859 15.3567V9.66042", stroke: color || '#11245A', strokeWidth: "1.2" })));
};

export { Rosette as default };
