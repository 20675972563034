import { __rest } from '../../_virtual/_tslib.esm.js';
import React__default from 'react';
import SvgIcon from '../../node_modules/@mui/material/SvgIcon/SvgIcon.esm.js';
import Grid from '../../node_modules/@mui/material/Grid/Grid.esm.js';
import Typography from '../../node_modules/@mui/material/Typography/Typography.esm.js';

const ConnectionsBadge = (_a) => {
    var { pathFill = '#112971', withCheck = true, withCross = false, count, stroke = '#112971', innerStroke = '#112971', crossStroke = '#CC4F4A', fill = 'none' } = _a, props = __rest(_a, ["pathFill", "withCheck", "withCross", "count", "stroke", "innerStroke", "crossStroke", "fill"]);
    return (React__default.createElement(React__default.Fragment, null, withCross ? (React__default.createElement(SvgIcon, Object.assign({ viewBox: "0 0 16 16", fill: "none" }, props),
        React__default.createElement("path", { d: "M7.49711 2.24561C7.89727 1.71788 8.69062 1.71788 9.09078 2.24561L9.50835 2.79631C9.94502 3.37221 10.6584 3.66772 11.3744 3.56927L12.0591 3.47513C12.7152 3.38492 13.2762 3.94591 13.186 4.60202L13.0919 5.28669C12.9934 6.00269 13.2889 6.71611 13.8648 7.15279L14.4155 7.57035C14.9433 7.97051 14.9433 8.76387 14.4155 9.16402L13.8648 9.58159C13.2889 10.0183 12.9934 10.7317 13.0919 11.4477L13.186 12.1323C13.2762 12.7885 12.7152 13.3495 12.0591 13.2592L11.3744 13.1651C10.6584 13.0667 9.94503 13.3622 9.50835 13.9381L9.09078 14.4888C8.69062 15.0165 7.89727 15.0165 7.49711 14.4888L7.07954 13.9381C6.64287 13.3622 5.92945 13.0667 5.21345 13.1651L4.52878 13.2592L4.59689 13.7546L4.52878 13.2592C3.87267 13.3495 3.31168 12.7885 3.40189 12.1323L3.49603 11.4477C3.59447 10.7317 3.29897 10.0183 2.72307 9.58159L2.17237 9.16402C1.64463 8.76387 1.64463 7.97051 2.17237 7.57035L2.72307 7.15279C3.29897 6.71611 3.59447 6.0027 3.49603 5.28669L3.40189 4.60203C3.31168 3.94591 3.87267 3.38492 4.52878 3.47513L5.21345 3.56927C5.92945 3.66772 6.64287 3.37221 7.07954 2.79631L7.49711 2.24561Z", fill: fill, stroke: crossStroke }),
        React__default.createElement("path", { d: "M5.81488 5.91004L10.773 10.8243", stroke: innerStroke, strokeWidth: "1.4", strokeLinecap: "round" }),
        React__default.createElement("path", { d: "M10.7511 5.88812L5.83684 10.8463", stroke: innerStroke, strokeWidth: "1.4", strokeLinecap: "round" }))) : (React__default.createElement(React__default.Fragment, null, withCheck ? (React__default.createElement(SvgIcon, Object.assign({ viewBox: "0 0 16 16", fill: "none" }, props),
        React__default.createElement("path", { d: "M6.72086 2.9828C7.25661 1.94292 8.74339 1.94292 9.27914 2.9828V2.9828C9.60106 3.60764 10.3273 3.90846 10.9968 3.69426V3.69426C12.1109 3.33779 13.1622 4.3891 12.8057 5.50323V5.50323C12.5915 6.1727 12.8924 6.89894 13.5172 7.22086V7.22086C14.5571 7.75661 14.5571 9.24339 13.5172 9.77914V9.77914C12.8924 10.1011 12.5915 10.8273 12.8057 11.4968V11.4968C13.1622 12.6109 12.1109 13.6622 10.9968 13.3057V13.3057C10.3273 13.0915 9.60106 13.3924 9.27914 14.0172V14.0172C8.74339 15.0571 7.25661 15.0571 6.72086 14.0172V14.0172C6.39894 13.3924 5.6727 13.0915 5.00323 13.3057V13.3057C3.8891 13.6622 2.83779 12.6109 3.19426 11.4968V11.4968C3.40846 10.8273 3.10764 10.1011 2.4828 9.77914V9.77914C1.44292 9.24339 1.44292 7.75661 2.4828 7.22086V7.22086C3.10764 6.89894 3.40846 6.1727 3.19426 5.50323V5.50323C2.83779 4.3891 3.8891 3.33779 5.00323 3.69426V3.69426C5.6727 3.90846 6.39894 3.60764 6.72086 2.9828V2.9828Z", stroke: stroke, strokeWidth: "1.2", fill: fill }),
        React__default.createElement("path", { d: "M5.73438 8.75L7.07812 10.0938L10.2656 6.90625", stroke: innerStroke, strokeWidth: "1.2", strokeLinecap: "round", fill: fill, strokeLinejoin: "round" }))) : (React__default.createElement(Grid, { position: "relative", container: true, justifyContent: "center", alignContent: "center", alignItems: "center" },
        React__default.createElement(SvgIcon, Object.assign({ sx: {
                position: 'absolute',
                aspectRatio: '1/1',
                width: 'calc(120%)',
                height: 'auto'
            }, viewBox: "0 0 29 28", fill: "none" }, props),
            React__default.createElement("path", { d: "M12.0228 2.96559C13.0943 0.885842 16.0678 0.885843 17.1393 2.96559V2.96559C17.7832 4.21529 19.2357 4.81693 20.5746 4.38853V4.38853C22.8028 3.67558 24.9055 5.77821 24.1925 8.00647V8.00647C23.7641 9.3454 24.3658 10.7979 25.6155 11.4417V11.4417C27.6952 12.5132 27.6952 15.4868 25.6155 16.5583V16.5583C24.3658 17.2021 23.7641 18.6546 24.1925 19.9935V19.9935C24.9055 22.2218 22.8028 24.3244 20.5746 23.6115V23.6115C19.2357 23.1831 17.7832 23.7847 17.1393 25.0344V25.0344C16.0678 27.1142 13.0943 27.1142 12.0228 25.0344V25.0344C11.3789 23.7847 9.92645 23.1831 8.58752 23.6115V23.6115C6.35926 24.3244 4.25663 22.2218 4.96958 19.9935V19.9935C5.39798 18.6546 4.79634 17.2021 3.54665 16.5583V16.5583C1.4669 15.4868 1.4669 12.5132 3.54665 11.4417V11.4417C4.79634 10.7979 5.39798 9.3454 4.96958 8.00647V8.00647C4.25663 5.77821 6.35926 3.67558 8.58752 4.38853V4.38853C9.92645 4.81693 11.3789 4.21529 12.0228 2.96559V2.96559Z", stroke: pathFill, strokeWidth: "2", fill: "none" })),
        React__default.createElement(Typography, { sx: {
                fontSize: '11px',
                fontWeight: 600,
                textAlign: 'center',
                lineHeight: '11px',
                padding: '8px',
                marginBottom: '-1px'
            }, color: "text.darkNavy" }, count)))))));
};

export { ConnectionsBadge as default };
