import styledComponent from '../../node_modules/@mui/material/styles/styled.esm.js';

const Container = styledComponent('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px'
});
const LinksContainer = styledComponent('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
    '&>span': {
        color: '#A6A6A6',
        textAlign: 'center',
        fontFamily: `Roboto, sans-serif`,
        fontSize: '8px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal'
    }
});
const Link = styledComponent('a')({
    color: '#A6A6A6',
    textAlign: 'center',
    fontFamily: `Roboto, sans-serif`,
    fontSize: '8px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'
});

export { Container, Link, LinksContainer };
