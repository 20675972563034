import { __rest } from '../../_virtual/_tslib.esm.js';
import React__default from 'react';
import SvgIcon from '../../node_modules/@mui/material/SvgIcon/SvgIcon.esm.js';

const Menu = (_a) => {
    var { pathFill } = _a, props = __rest(_a, ["pathFill"]);
    return (React__default.createElement(SvgIcon, Object.assign({ width: "24", height: "24", viewBox: "0 0 24 24" }, props),
        React__default.createElement("path", { d: "M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z", fill: pathFill || '#2B59E0' })));
};

export { Menu as default };
