import { AlternativeNavigationSearchTypes, SearchTypes } from '../providers/SearchProfilesPage/types'

export const TENANTS = {
  UK: 'uk',
  DE: 'de',
  AT: 'de-at',
  AU: 'au',
  EN_UAE: 'en-ae',
  AR_UAE: 'ar-ae',
  IE: 'ie',
  WPA: 'wpa'
}

export const searchTypes = [SearchTypes.practices, SearchTypes.specialists, SearchTypes.dentist]

export const newSearchTypesPractices = [
  SearchTypes.pharmacy,
  SearchTypes.careHome,
  SearchTypes.hospital,
  SearchTypes.healthAndSocialCare
]

export const practiceTypes = [
  AlternativeNavigationSearchTypes.careHome,
  AlternativeNavigationSearchTypes.hospital,
  AlternativeNavigationSearchTypes.pharmacy,
  AlternativeNavigationSearchTypes.dentist,
  AlternativeNavigationSearchTypes.practices
]

export const getNewPracticesName = (key: SearchTypes) => {
  const obj: Partial<Record<SearchTypes, string>> = {
    [SearchTypes.careHome]: 'care-home',
    [SearchTypes.pharmacy]: 'pharmacies',
    [SearchTypes.hospital]: 'hospitals',
    [SearchTypes.healthAndSocialCare]: 'homecare'
  }

  return (
    obj[key as SearchTypes.careHome | SearchTypes.pharmacy | SearchTypes.hospital | SearchTypes.healthAndSocialCare] ??
    ''
  )
}

const DEFAULT_COUNTRY = {
  de: 'Deutschland',
  uk: 'United Kingdom',
  'de-at': 'Österreich',
  au: 'Australia',
  'en-ae': 'United Arab Emirates',
  'ar-ae': 'United Arab Emirates'
}

export const getDefaultCountry = (locale: string) => {
  return DEFAULT_COUNTRY?.[locale as keyof typeof DEFAULT_COUNTRY] || DEFAULT_COUNTRY.uk
}

export const getHubspotData = (locale: string) => {
  switch (locale) {
    case TENANTS.DE:
    case TENANTS.AT:
      return {
        target: '#hbs-form',
        portalId: '25040436',
        formId: '080db213-6614-46ec-9e7f-3e864ccab306'
      }
    default:
      return {
        target: '#hbs-form',
        portalId: '25040436',
        formId: 'd83e939a-0113-47c0-ac77-1cec1bfbef39'
      }
  }
}

export const LANGUAGES = {
  EN: 'en',
  DE: 'de',
  AR: 'ar'
}

export const CLAIM_YOUR_PROFILE_ROUTES = [
  '/claim-your-profile',
  '/beanspruchen-sie-ihr-profil',
  '/claim-your-profile-connect',
  '/beanspruchen-sie-ihr-profil-vernetzen'
]

export const CLAIM_YOUR_PROFILE_WPA_ROUTE = '/claim-your-profile-wpa'

export const GTM_ID = 'GTM-K73CL79'
export const WPA_GTM_ID = 'GTM-NBGD8F78'

export const RTL_TENANTS = [TENANTS.AR_UAE]
export const RIGHT_TO_LEFT = 'rtl'
export const LEFT_TO_RIGHT = 'ltr'
export const MUI_RTL = 'muirtl'
export const MUI_LTR = 'muiltr'
export const DEFAULT_LOCALE = 'en'
export const PATH_TO_UNAVAILABLE_IMG = '/hermes-static/images/video-unavailable.webp'

export const remapDaysOfWeekIndex = [7, 1, 2, 3, 4, 5, 6] // Where: 7 - Sunday, 1 - Monday, ..., 6 - Saturday

export const YOUTUBE_REGEX = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/
export const VIMEO_REGEX = /^(https?:\/\/)?(www\.)?(vimeo\.com)\/.+$/
export const imageBasePathS3 = 'https://doctify-athena-static.s3.eu-central-1.amazonaws.com/images/athena-uk'
export const activeMarkerIndicator = `${imageBasePathS3}/static/icons/map-marker-active.svg`
export const inactiveMarkerIndicator = `${imageBasePathS3}/static/icons/map-marker-inactive.svg`
export const wpaMarkerIndicator = `${imageBasePathS3}/static/icons/wpa/wpa-map-marker.svg`
