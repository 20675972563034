import React__default from 'react';
import RecaptchaIcon from './recaptchaIcon.esm.js';
import { Container, LinksContainer, Link } from './styles.esm.js';

const GoogleRecaptchaLogo = () => {
    return (React__default.createElement(Container, null,
        React__default.createElement(RecaptchaIcon, null),
        React__default.createElement(LinksContainer, null,
            React__default.createElement(Link, { href: 'https://policies.google.com/privacy?hl=en', target: '_blank' }, "Privacy"),
            React__default.createElement("span", null, "-"),
            React__default.createElement(Link, { href: 'https://policies.google.com/terms?hl=en', target: '_blank' }, "Terms"))));
};

export { GoogleRecaptchaLogo };
