type SpecialtySettings = {
  id: number
  name: string
  slug: string
}

type SearchSettings = {
  COUNTRY: {
    NAME: string
    SHORT_NAME: string
  }
  DEFAULT_SEARCH_LAT: number
  DEFAULT_SEARCH_LON: number
  DEFAULT_GOOGLE_PLACE_ID: string
  SEARCH_DISTANCE_UNITS: 'mi' | 'km'
  SEARCH_DISTANCE_MULTIPLIER: number
  SEARCH_DISTANCE_DEFAULT_VALUE: number
  GOOGLE_AUTOCOMPLETE_COUNTRY_RESTRICTIONS: string[]
  SEARCH_WIDGET_SHOW_SPECIALISTS: boolean
  DENTISTRY?: SpecialtySettings
  DISTANCES?: number[]
  SEARCH_BY_AUTOKEYWORDS_ENABLED?: boolean
  CARE_HOME?: SpecialtySettings
}

export type FooterLinkProps = {
  href: string
  title: { [key: string]: string }
  external?: boolean
  defaultLocale?: string
  isCookieBanner?: boolean
  defaultHost?: string
}

export enum FooterSocialTypes {
  TWITTER = 'twitter',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  LINKEDIN = 'linkedIn'
}

export interface SocialListProps {
  href: { [key: string]: string }
  type: FooterSocialTypes
}

export type FooterLinksListProps = { name: { [key: string]: string }; links: FooterLinkProps[] }

export interface TenantSettings {
  contactPhoneNumber: string
  defaultCurrency: string[]
  defaultLocations: { country: string; name: string; placeId: string }[]
  languages: string[]
  locales: string[]
  name: string
  gmcRegistrationBodyId?: number
  showBanner: boolean
  gtmKey?: string
  skipUrlLanguage: boolean
  footerSocialLinks: SocialListProps[]
  timezone: string
  defaultSearch: {
    lat: number
    lon: number
  }
  footerColumnLinks?: FooterLinksListProps[]
  dashboardUrl: string
  searchDistanceUnits: 'mi' | 'km'
  searchDistanceDefaultValue?: number
  search?: SearchSettings
}
