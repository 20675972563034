function AppBar() {
    return {
        styleOverrides: {
            root: {
                backgroundColor: 'white',
                boxShadow: 'none'
            }
        }
    };
}

export { AppBar as default };
