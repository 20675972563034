import React, { useMemo } from 'react'
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
  GoogleRecaptchaLogo,
  SxProps,
  useMediaQuery,
  useTheme
} from '@hermes/web-components'
import useTranslateMessage from '../../hooks/useTranslateMessage'
import Socials from './Socials'
import { StyledBox, StyledInfoBox, StyledLink, GoogleLogoWrapper } from './styles'
import { FooterLinkProps, FooterLinksListProps, SocialListProps } from '../../types'
import { TEST_IDS } from '../../__tests__/testUtils/constants'
import NextImage from 'next/image'
import { CLAIM_YOUR_PROFILE_ROUTES, CLAIM_YOUR_PROFILE_WPA_ROUTE } from '../../constants/global'

type Props = {
  locale: string
  lang: string
  footerSocialLinks?: SocialListProps[]
  footerColumnLinks?: FooterLinksListProps[]
  defaultHost?: string
  pathname?: string
}

type FooterItemProps = { children: [JSX.Element, JSX.Element]; sx?: SxProps; isMobile?: boolean }

const displayCaptchaLogoPaths = ['/specialist/[slug]', '/practice/[slug]', '/find/[...search]']
const claimYourProfileRoutes = [...CLAIM_YOUR_PROFILE_ROUTES, CLAIM_YOUR_PROFILE_WPA_ROUTE]

const FooterGridItem = ({ children, sx, isMobile }: FooterItemProps) => {
  const [HeaderItem, BottomItem] = children

  return (
    <Grid item xs={12} sm={12} md={3} mb={3} sx={isMobile ? {} : sx}>
      <Box pb={1}>{HeaderItem}</Box>
      <Box pt={1}>{BottomItem}</Box>
    </Grid>
  )
}

const openCookieBanner = () => {
  // allow underscore usage
  // eslint-disable-next-line no-underscore-dangle
  window.__tcfapi('displayConsentUi', 2, () => {})
}

const WpaFooter = ({ lang, footerColumnLinks, footerSocialLinks, pathname = '' }: Props) => {
  const translate = useTranslateMessage()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const date = new Date()
  const currentYear = date.getFullYear()

  const FooterListItem = ({ href, title, isCookieBanner }: FooterLinkProps) => {
    const onClickOrHref = useMemo(
      () => (isCookieBanner ? { onClick: openCookieBanner, component: 'button' } : { href }),
      [isCookieBanner, href]
    )

    return (
      <ListItem sx={{ pl: 0 }}>
        <StyledLink draggable={false} {...onClickOrHref} underline="none" variant="body2">
          {title[lang]}
        </StyledLink>
      </ListItem>
    )
  }

  return (
    <footer style={{ contentVisibility: 'auto', containIntrinsicHeight: '600px' }} data-testid={TEST_IDS.FOOTER}>
       {(displayCaptchaLogoPaths.includes(pathname) ||
        claimYourProfileRoutes.some((route) => pathname.includes(route))) && (
        <GoogleLogoWrapper>
          <GoogleRecaptchaLogo />
        </GoogleLogoWrapper>
      )}
      <StyledBox p={{ xs: 2 }} py={{ xs: 4, sm: 5 }}>
        <Box sx={{ width: '100%' }}>
          <Grid
            container
            sx={{ width: '100%', margin: isMobile ? 0 : '' }}
            justifyContent="space-between"
            display="flex"
            spacing={{ xs: isMobile ? '' : 3 }}
          >
            <FooterGridItem
              sx={{
                marginLeft: '111px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                maxWidth: isMobile ? '100%' : ''
              }}
              data-testid={TEST_IDS.FOOTER_LOGO_AND_CONTACTS}
              isMobile={isMobile}
            >
              <NextImage
                src={`/hermes-static/wpa-logo-navy.svg`}
                alt="Doctify logo"
                width={isMobile ? 280 : 340}
                height={43}
                objectFit="contain"
                layout="fixed"
                priority
              />
              <Socials data-testid={TEST_IDS.FOOTER_SOCIALS} socialList={footerSocialLinks} lang={lang} />
            </FooterGridItem>
            <Box
              display="flex"
              sx={{ width: isMobile ? '100%' : '50%', flexDirection: isMobile ? 'column' : 'row' }}
              pt={'24px'}
            >
              {footerColumnLinks?.map(({ name, links }) => (
                <FooterGridItem key={`grid-item-${name[lang]?.trim()}`}>
                  <Typography
                    sx={{ fontWeight: 'bold' }}
                    data-testid={TEST_IDS.FOOTER_LINK_NAME}
                    variant="body1"
                    color="#051851"
                  >
                    {name[lang]}
                  </Typography>
                  <List dense={true} sx={{ border: 0, background: 'none' }}>
                    {links.map((item) => (
                      <FooterListItem
                        data-testid={TEST_IDS.FOOTER_LINK_ITEM}
                        key={`list-${name[lang]?.trim()}-item-${item.title[lang]?.trim()}`}
                        {...item}
                      />
                    ))}
                  </List>
                </FooterGridItem>
              ))}
            </Box>
          </Grid>
        </Box>
      </StyledBox>
      <StyledInfoBox py={{ xs: 3, sm: 2 }} pb={{ xs: 10 }} data-testid={TEST_IDS.FOOTER_BOTTOM}>
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'center', sm: 'space-between' },
            flexDirection: {
              xs: 'column',
              sm: 'row'
            }
          }}
        >
          <Typography variant="body2" mb={{ xs: 1, sm: 0 }} data-testid={TEST_IDS.FOOTER_BOTTOM_RIGHTS}>
            {translate('footer.rights', { currentYear })}
          </Typography>
        </Container>
      </StyledInfoBox>
    </footer>
  )
}

export default WpaFooter
