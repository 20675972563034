function PaginationItem(theme) {
    return {
        styleOverrides: {
            rounded: {
                fontSize: '12px',
                borderRadius: '2px',
                height: '28px',
                color: theme.palette.primary.main,
                '&.MuiPaginationItem-previousNext': {
                    border: `1px solid ${theme.palette.system.blue}`,
                    opacity: 1,
                    '&.Mui-disabled': {
                        borderColor: theme.palette.grey[500],
                        '.MuiSvgIcon-root': {
                            fill: theme.palette.grey[500]
                        }
                    }
                },
                '&.MuiPaginationItem-ellipsis': {
                    height: 'auto'
                }
            },
            root: {
                '& .Mui-selected': {
                    color: theme.palette.common.white
                }
            }
        }
    };
}

export { PaginationItem as default };
