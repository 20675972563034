import { Grid, styledComponent } from '@hermes/web-components'
import { WpaHeaderProps } from './index'

export const WpaHeaderContainer = styledComponent(Grid, {
  shouldForwardProp: (prop) => prop !== 'shouldReverse' && prop !== 'logoAlign'
})<{
  shouldReverse?: boolean
  logoAlign?: WpaHeaderProps['logoAlign']
}>(({ theme, shouldReverse, logoAlign }) => ({
  backgroundColor: shouldReverse ? '#fff' : '#142F76',
  height: 64,
  pl: { sm: 2.5, xs: 0 },
  pr: 2,
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1100,
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: 'common.white',
  ...(logoAlign && { justifyContent: logoAlign }),
  [theme.breakpoints.down('sm')]: {
    height: '48px !important',
    justifyContent: 'center !important',
    img: {
      height: '27px !important'
    }
  }
}))
