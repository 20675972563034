const palette = {
  background: {
    keywordPrimary: '#EBFFF7',
    keywordSecondary: '#E2EEFA',
    keywordOther: '#E2EEFA'
  },
  border: {
    keywordPrimary: '#3B7D61',
    keywordSecondary: '#2258B1',
    keywordOther: '#2258B1'
  },
  titleColor: {
    color: 'rgb(44, 51, 65)'
  },
  locationCard: {
    lineHeight: 1.6
  }
}

export default palette
