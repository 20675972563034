export const isValidUrl = (urlString: string) => {
  try {
    return Boolean(new URL(urlString))
  } catch (e) {
    return false
  }
}

export const getProviderUrl = (tenant: string) => {
  if (tenant === 'uk' || tenant === 'wpa' || tenant === 'ie') {
    return '/provider/join-doctify/'
  }

  if (tenant === 'de' || tenant === 'de-at') {
    return '/about/business-center/'
  }

  // au, en-ae, ar-ae, test
  return '/about/join-doctify/'
}

export const hasOnlyLocation = (queryString: string): boolean => {
  const params = new URLSearchParams(queryString)

  const keys = Array.from(params.keys())
  return keys.length === 1 && keys[0] === '#location'
}
