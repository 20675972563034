import { __rest } from '../../_virtual/_tslib.esm.js';
import React__default from 'react';
import SvgIcon from '../../node_modules/@mui/material/SvgIcon/SvgIcon.esm.js';

const Logo = (_a) => {
    var { lettersColor } = _a, props = __rest(_a, ["lettersColor"]);
    return (React__default.createElement(SvgIcon, Object.assign({ width: "120", height: "32", viewBox: "0 0 120 32" }, props),
        React__default.createElement("path", { d: "M48.102 24.5771C49.9238 24.5771 51.5824 23.7822 52.2349 22.6693V24.3651H55.1987V5.60527H52.2349V12.68C51.4192 11.5671 49.815 10.7987 48.0476 10.7987C43.9147 10.7987 41.5491 13.6603 41.5491 17.6879C41.5491 21.7154 43.9147 24.5771 48.102 24.5771ZM48.4827 22.0599C46.0899 22.0599 44.5129 20.3111 44.5129 17.6879C44.5129 15.0382 46.0899 13.3159 48.4827 13.3159C50.8754 13.3159 52.4525 15.0382 52.4525 17.6879C52.4525 20.3111 50.8754 22.0599 48.4827 22.0599Z", fill: lettersColor || '#051851' }),
        React__default.createElement("path", { d: "M63.2059 24.5771C67.3117 24.5771 70.2754 21.6889 70.2754 17.6879C70.2754 13.6868 67.3117 10.7987 63.2059 10.7987C59.073 10.7987 56.1365 13.6868 56.1365 17.6879C56.1365 21.6889 59.073 24.5771 63.2059 24.5771ZM63.2059 22.0599C60.7588 22.0599 59.1274 20.2581 59.1274 17.6879C59.1274 15.1177 60.7588 13.3159 63.2059 13.3159C65.6259 13.3159 67.2845 15.1177 67.2845 17.6879C67.2845 20.2581 65.6259 22.0599 63.2059 22.0599Z", fill: lettersColor || '#051851' }),
        React__default.createElement("path", { d: "M78.0723 24.5771C81.4167 24.5771 83.7823 22.9078 84.3533 20.1256L81.4439 19.5692C81.1176 21.1325 79.894 22.0599 78.1539 22.0599C75.8155 22.0599 74.2656 20.3376 74.2656 17.6879C74.2656 15.0382 75.7883 13.3159 78.1539 13.3159C79.894 13.3159 81.172 14.3493 81.4439 15.8596L84.3261 15.3032C83.8638 12.5475 81.4439 10.7987 78.0723 10.7987C73.9665 10.7987 71.1931 13.5809 71.1931 17.6879C71.1931 21.7949 73.9665 24.5771 78.0723 24.5771Z", fill: lettersColor || '#051851' }),
        React__default.createElement("path", { d: "M93.5124 24.2591L93.322 21.6889C92.8054 21.9274 92.18 22.0069 91.6634 22.0069C90.5486 22.0069 89.7873 21.318 89.7873 19.9931V13.4749H93.322V11.0106H89.7873V7.0361H86.8235V11.0106H84.4308V13.4749H86.8235V20.1521C86.8235 23.1993 88.7541 24.5771 91.3371 24.5771C92.0985 24.5771 92.8598 24.4711 93.5124 24.2591Z", fill: lettersColor || '#051851' }),
        React__default.createElement("path", { d: "M96.3926 8.7849C97.5074 8.7849 98.3503 7.9635 98.3503 6.93011C98.3503 5.87024 97.5074 5.04883 96.3926 5.04883C95.305 5.04883 94.4893 5.87024 94.4893 6.93011C94.4893 7.9635 95.305 8.7849 96.3926 8.7849ZM94.9244 24.3651H97.8881V11.0106H94.9244V24.3651Z", fill: lettersColor || '#051851' }),
        React__default.createElement("path", { d: "M101.594 24.3651H104.557V13.4749H107.902V11.0106H104.557V9.81828C104.557 8.49344 105.319 7.64553 106.488 7.64553C106.95 7.64553 107.412 7.72502 107.902 7.9105L108.065 5.47278C107.467 5.2873 106.787 5.18131 106.107 5.18131C103.497 5.18131 101.594 6.85062 101.594 9.87128V11.0106H99.3368V13.4749H101.594V24.3651Z", fill: lettersColor || '#051851' }),
        React__default.createElement("path", { d: "M116.955 11.0106L113.284 20.2581L109.695 11.0106H106.486L111.816 23.9677L109.749 29.1876H112.795L120 11.0106H116.955Z", fill: lettersColor || '#051851' }),
        React__default.createElement("path", { d: "M27.8876 5.2772C22.9404 4.75957 20.5766 8.8353 17.9631 9.65604C17.667 9.68169 17.3617 9.68402 17.0495 9.67702C17.0633 9.68169 17.0772 9.68868 17.0911 9.69334C19.8203 9.92884 23.2364 8.46223 26.833 11.4234C33.2859 16.7349 28.8382 26.782 17.5837 30.7901C18.3192 31.0489 19.0848 31.2821 19.8781 31.4873C21.4162 31.8837 23.0699 31.5106 24.3744 30.5989C38.2794 20.855 38.0759 6.34743 27.8876 5.2772Z", fill: "#00E5D0" }),
        React__default.createElement("path", { d: "M27.8876 5.2772C22.9404 4.75957 20.5766 8.8353 17.9631 9.65604C17.667 9.68169 17.3617 9.68402 17.0495 9.67702C17.0633 9.68169 17.0772 9.68868 17.0911 9.69334C19.8203 9.92884 23.2364 8.46223 26.833 11.4234C33.2859 16.7349 28.8382 26.782 17.5837 30.7901C18.3192 31.0489 19.0848 31.2821 19.8781 31.4873C21.4162 31.8837 23.0699 31.5106 24.3744 30.5989C38.2794 20.855 38.0759 6.34743 27.8876 5.2772Z", fill: "url(#paint0_radial_2832_26749)" }),
        React__default.createElement("path", { d: "M7.32113 5.2772C12.2684 4.75957 14.6322 8.8353 17.2457 9.65604C17.5418 9.68169 17.8471 9.68402 18.1593 9.67702C18.1454 9.68169 18.1315 9.68868 18.1177 9.69334C15.3885 9.92884 11.9723 8.46223 8.37581 11.4234C1.92286 16.7349 6.37054 26.782 17.625 30.7901C16.8895 31.0489 16.124 31.2821 15.3306 31.4873C13.7926 31.8837 12.1389 31.5106 10.8344 30.5989C-3.07065 20.855 -2.86712 6.34743 7.32113 5.2772Z", fill: "url(#paint1_radial_2832_26749)" }),
        React__default.createElement("path", { d: "M17.5846 30.7902C16.8213 31.0584 16.0211 31.3079 15.1861 31.527C7.49347 27.9246 3.30253 21.452 5.31242 16.4692C4.63475 21.6712 9.27439 27.871 17.5846 30.7902Z", fill: "url(#paint2_linear_2832_26749)" }),
        React__default.createElement("path", { d: "M26.8328 11.4231C23.2362 8.46192 19.8201 9.92853 17.0909 9.69303C17.077 9.68837 17.0631 9.68138 17.0493 9.67671C14.4889 9.62075 11.4266 8.74638 8.22098 11.3881C1.74954 16.7183 6.24809 26.8097 17.5812 30.7898C28.838 26.7817 33.2857 16.7346 26.8328 11.4231Z", fill: "url(#paint3_radial_2832_26749)" }),
        React__default.createElement("path", { d: "M12.8717 3.95324C11.3672 3.11064 11.1784 1.27157 12.6731 0.974416C13.948 0.720946 15.2997 0.96051 16.428 1.69308C18.3283 2.92647 18.8193 6.01824 17.9175 7.8914C17.7263 8.28868 17.2027 8.26312 16.9393 7.90951C15.8201 6.40679 14.3762 4.79584 12.8717 3.95324Z", fill: "url(#paint4_radial_2832_26749)" }),
        React__default.createElement("defs", null,
            React__default.createElement("radialGradient", { id: "paint0_radial_2832_26749", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(19.5236 14.7035) rotate(-33.658) scale(22.8641 23.0496)" },
                React__default.createElement("stop", { offset: "0.536654", stopColor: "#1CDFCD" }),
                React__default.createElement("stop", { offset: "1", stopColor: "#00AA9C" })),
            React__default.createElement("radialGradient", { id: "paint1_radial_2832_26749", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(18.139 11.3017) rotate(178.58) scale(18.1446 18.2918)" },
                React__default.createElement("stop", { stopColor: "#1DDFCE" }),
                React__default.createElement("stop", { offset: "0.992249", stopColor: "#2B5AE0" })),
            React__default.createElement("linearGradient", { id: "paint2_linear_2832_26749", x1: "15.3965", y1: "30.9763", x2: "5.75828", y2: "21.4633", gradientUnits: "userSpaceOnUse" },
                React__default.createElement("stop", { stopColor: "#2B59E0" }),
                React__default.createElement("stop", { offset: "1", stopColor: "#071037", stopOpacity: "0" })),
            React__default.createElement("radialGradient", { id: "paint3_radial_2832_26749", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(30.0578 24.7363) rotate(-151.073) scale(25.7248 25.9335)" },
                React__default.createElement("stop", { offset: "0.08836", stopColor: "#2B59E0" }),
                React__default.createElement("stop", { offset: "0.796792", stopColor: "#1DDFCE" })),
            React__default.createElement("radialGradient", { id: "paint4_radial_2832_26749", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(13.4617 3.59778) rotate(33.1769) scale(8.22365 7.95467)" },
                React__default.createElement("stop", { offset: "0.192111", stopColor: "#2B59E0" }),
                React__default.createElement("stop", { offset: "0.793069", stopColor: "#1DDFCE" })))));
};

export { Logo as default };
