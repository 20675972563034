/* eslint-disable sonarjs/no-duplicate-string */
import { theme } from '@hermes/web-components'
import { CustomStyles } from './customStyles'

const primaryMain = 'primary.main'
const borderTransparent = '1px solid transparent'
const textDarkNavy = 'text.darkNavy'

const custom: CustomStyles = {
  bookingAvailableButton: {
    width: '100%',
    display: 'flex',
    flex: 1,
    gap: '4px',
    fontWeight: 500,
    borderRadius: '24px'
  },
  basicProfileAlert: {
    container: {
      rowGap: '12px'
    },
    text: {
      display: 'flex',
      alignItems: 'center'
    },
    grid: {
      height: '28px',
      padding: '4px 12px',
      borderRadius: '24px',
      backgroundColor: '#1A3586'
    },
    link: {
      display: 'flex',
      color: '#fff',
      fontWeight: 500,
      fontSize: '13px'
    }
  },
  common: {
    searchIconColor: primaryMain,
    circleChartColors: [
      theme.palette.system.aqua.toString(),
      theme.palette.system.coral.toString(),
      theme.palette.system.lightBlue.toString(),
      '#2B59E0',
      '#CFD4DF',
      theme.palette.secondary.light.toString()
    ],
    ratingBarChartColors: [
      theme.palette.system.aqua.toString(),
      theme.palette.system.coral.toString(),
      theme.palette.system.lightBlue.toString(),
      '#CFD4DF'
    ],
    linearChartEndorsementsColors: [
      theme.palette.system.aqua,
      theme.palette.system.coral,
      theme.palette.system.lightBlue,
      theme.palette.system.green,
      theme.palette.grey[300]
    ],
    mainColor: primaryMain
  },
  RatingBar: {
    sectionTitle: {
      textTransform: 'uppercase'
    }
  },
  LocationOnIcon: {
    marginRight: '4px'
  },
  DialogContent: {
    padding: '24px 8px 24px 24px',
    background: ''
  },
  scrollbarThumb: {
    backgroundColor: '#2b59e0'
  },
  ProfileIntro: {
    profileTitleTypography: {
      fontSize: '27px',
      fontWeight: 700,
      color: textDarkNavy,
      [theme.breakpoints.down('sm')]: {
        fontSize: '21px'
      }
    },
    profileKeywordTypography: {
      fontSize: '13.33px',
      fontWeight: 500,
      color: textDarkNavy,
      lineHeight: '20px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px'
      }
    }
  },
  SearchLayout: {
    backgroundColor: theme.palette.common.white,
    gap: 2,
    marginTop: '0px !important'
  },
  SearchBar: {
    position: 'relative',
    MenuItem: {
      backgroundColor: '#F4F7FD',
      color: '#11245A'
    },
    SelectedMenuItem: {
      fontWeight: 500
    },
    customPositionDesktopModal: {
      left: '30%'
    },
    customPositionDesktopModalForDoctify: {
      left: 0
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: 'inherit',
      right: '-100%',
      top: 0
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: 'inherit',
      left: '-100%',
      top: 0
    },
    searchButton: {
      position: 'absolute',
      width: '42px',
      height: '42px',
      top: 9,
      right: 6
    },
    mobile: {
      container: {
        padding: '0 !important'
      },
      section: {
        position: 'relative',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        '&:after': {
          content: '""',
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: 'inherit',
          right: '-100%',
          top: 0
        },
        '&:before': {
          content: '""',
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: 'inherit',
          left: '-100%',
          top: 0
        },
        '&:first-of-type': {
          paddingTop: theme.spacing(2)
        }
      },
      controlsContainer: {
        marginTop: '0 !important',
        paddingTop: '16px',
        paddingBottom: '16px',
        backgroundColor: 'background.default'
      },
      searchButton: {
        mt: 1,
        height: '40px',
        borderRadius: '24px'
      },
      searchResultAutocompleteModal: {
        position: 'relative',
        backgroundColor: 'background.default',
        paddingTop: '42px',
        arrowRight: {
          color: '#11245a'
        },
        backButtonWrapper: {
          display: 'contents'
        },
        backButton: {
          position: 'absolute',
          top: 0
        }
      },
      buttonHover: {
        height: '30px',
        borderRadius: '15px',
        borderColor: theme.custom?.SearchFilters?.mobile?.mainColor,
        marginLeft: '4px',
        '&:focus': { outlineOffset: '-1px' },
        '&:hover, &:focus': {
          svg: { fill: 'white' },
          '.MuiTypography-root': {
            color: 'white'
          }
        }
      }
    },
    resultsList: {
      div: {
        padding: '6px 16px'
      },
      a: {
        backgroundColor: 'secondary.light',
        borderRadius: '16px',
        padding: '2px 8px',
        color: '#2B59F4',
        textDecoration: 'none',
        maxWidth: '100%',
        display: 'inline-flex',
        fontWeight: 500,
        fontSize: '13.33px',
        boxSizing: 'border-box',
        position: 'relative',
        span: {
          boxSizing: 'border-box',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        },
        border: borderTransparent,
        '&:hover': {
          border: '1px solid #2B59F4'
        }
      }
    },
    searchResultBadgeContainer: {
      [theme.breakpoints.up('md')]: {
        gap: '6px'
      },
      [theme.breakpoints.down('md')]: {
        gap: '2px'
      }
    }
  },
  SearchFilters: {
    chipsVariant: 'outlined',
    mobile: {
      mainColor: primaryMain,
      textColor: 'text.black700'
    },
    select: {
      '.MuiSelect-select': {
        padding: '10.5px 14px'
      }
    },
    menuItemHover: {
      backgroundColor: '#EAEEFC !important',
      color: '#2B59E0 !important'
    }
  },
  SearchProfileCard: {
    content: {
      gap: 1
    }
  },
  SortMenuItemSelected: {
    color: ''
  },
  IntroSection: {},
  QualificationsSection: {},
  SpecialistPage: {
    tooltip: {},
    profileTab: {
      padding: '10px 12px'
    },
    common: {
      chipsTextColor: primaryMain,
      chipBackground: '#EAEEFC',
      chipBorderColor: '#EAEEFC',
      border: borderTransparent,
      borderSelected: borderTransparent,
      buttonBorderRadius: '24px'
    },
    detailsContainer: {
      gap: '8px'
    },
    LoadMoreButton: {
      backgroundColor: 'transparent',
      outline: 'none'
    },
    LoadMoreButtonFocus: {
      outline: 'none',
      backgroundColor: 'transparent',
      color: '#2B59E0'
    },
    LoadMoreButtonHover: {
      backgroundColor: 'primary.main',
      color: '#fff !important'
    },
    StarsRating: {
      padding: '16px'
    },
    RatingBarStyles: {},
    BookingCardLayoutName: {
      fontWeight: 500,
      color: '#11245A'
    },
    StyledScrollBar: {
      maxHeight: 'calc(calc(var(--vh, 1vh) * 100) - 120px)'
    },
    ProfileModalHeader: {
      mb: '14px'
    },
    ProfileHeaderTitle: {
      fontFamily: '',
      fontSize: '21px'
    },
    ratingBar: {
      title: {
        fontWeight: 700,
        textTransform: 'uppercase'
      },
      reviewText: {
        color: 'text.black900',
        fontSize: '15px'
      },
      trustScoreInfo: {
        count: {
          fontSize: '21px',
          lineHeight: '32px',
          color: textDarkNavy,
          fontWeight: 700,
          fontFamily: 'ES Klarheit Kurrent',
          [theme.breakpoints.down('sm')]: {
            fontFamily: 'Poppins',
            fontSize: '16px',
            lineHeight: '22px'
          }
        },
        infoText: {
          fontFamily: 'Poppins',
          fontSize: '13.33px',
          lineHeight: '20px'
        }
      }
    },
    profileMediaHeaderChips: {
      color: '#fff'
    },
    intro: {
      mt: '24px',
      gap: '24px'
    },
    about: {
      titleColor: '#051851',
      title: {
        mb: 2,
        fontSize: '18px',
        fontWeight: '500',
        lineHeight: '26px',
        fontFamily: 'Poppins'
      },
      subHeader: {
        lineHeight: '22px',
        fontSize: '16px'
      }
    },
    review: {
      chipsTextColor: '#1A3586',
      showMoreChips: theme.palette.secondary.light,
      showMoreBackgroundHover: theme.palette.grey[600],
      showMoreBorderHover: theme.palette.grey[200],
      showMoreTextColor: theme.palette.text.darkNavy900,
      chipsBorder: borderTransparent,
      chipsSelectedBackground: '#1A3586',
      chipActiveBackground: '#BBDEFB',
      chipActiveColor: '#fff'
    },
    endorsements: {
      chipsBackground: '#1129711A',
      chipsBorder: '1px solid #EAEEFC',
      chipsSelectedBackground: '#00E5D033',
      chipsSelectedBorder: '1px solid #EAEEFC',
      chipsTextColor: '#112971',
      endorsementsShowMoreChips: {
        border: 'none',
        color: theme.palette.primary.main,
        fontWeight: '600'
      },
      endorsementsChipsList: {
        cursor: 'pointer',
        fontWeight: '500',
        border: 'none',
        fontSize: '11.85px !important'
      },
      endorsementsShowMoreChipsText: {
        fontSize: '13.33px',
        lineHeight: '20px',
        fontWeight: 600
      },
      endorsementsCountText: {
        textTransform: 'uppercase',
        lineHeight: '20px',
        fontWeight: 600
      }
    }
  },
  TeamPracticePage: {
    keywords: {
      keywordsGroupsMargin: '48px'
    }
  },
  reviewsTitle: {
    fontSize: '30px',
    lineHeight: '40px',
    fontWeight: 400
  },
  locationCard: {
    lineHeight: '16px'
  },
  reviews: {
    chips: {
      border: '1px solid',
      borderColor: 'primary.main'
    },
    searchField: {
      iconColor: primaryMain
    }
  }
}

export default custom
