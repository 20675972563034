import { theme } from '@hermes/web-components'

const components = {
  MuiButton: {
    styleOverrides: {
      root: {
        color: theme.palette.common.white,
        backgroundColor: '#2258B1'
      },
      containedPrimary: {
        '&:hover': {
          background: 'linear-gradient(90deg, #2258B1 0%, #143977 100%)'
        }
      },
      outlinedPrimary: {
        '&:hover': {
          backgroundColor: '#E2EEFA',
          color: '#2258B1',
          '& .MuiSvgIcon-root': {
            fill: '#2258B1',
            color: '#2258B1'
          }
        }
      }
    }
  },
  MuiChip: {
    variants: [
      {
        props: { variant: 'aqua' },
        style: {
          backgroundColor: '#C6D7F4',
          borderColor: '#EAEEFC'
        }
      },
      {
        props: { variant: 'coral' },
        style: {
          backgroundColor: '#8AD3FD',
          borderColor: '#EAEEFC'
        }
      },
      {
        props: { variant: 'lightBlue' },
        style: {
          backgroundColor: '#00A3FF33',
          borderColor: '#EAEEFC'
        }
      },
      {
        props: { variant: 'blue' },
        style: {
          backgroundColor: '#EAEEFC',
          borderColor: '#F4F7FD'
        }
      }
    ]
  }
}

export default components
