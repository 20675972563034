import { __rest } from '../../_virtual/_tslib.esm.js';
import React__default from 'react';
import SvgIcon from '../../node_modules/@mui/material/SvgIcon/SvgIcon.esm.js';

const LocationPin = (_a) => {
    var { color } = _a, props = __rest(_a, ["color"]);
    return (React__default.createElement(SvgIcon, Object.assign({ width: "16", height: "18", viewBox: "0 0 16 18" }, props),
        React__default.createElement("path", { d: "M14.4308 7.73197C14.4308 12.0191 8.00008 17.0208 8.00008 17.0208C8.00008 17.0208 1.56934 12.0191 1.56934 7.73197C1.56934 4.22936 4.49747 1.30122 8.00008 1.30122C11.5027 1.30122 14.4308 4.22936 14.4308 7.73197Z", stroke: color || '#11245A', strokeWidth: "1.2", strokeLinecap: "round", strokeLinejoin: "round", fill: "none" }),
        React__default.createElement("path", { d: "M8.00003 9.87553C8.56854 9.87553 9.11377 9.64969 9.51577 9.24769C9.91777 8.84569 10.1436 8.30046 10.1436 7.73195C10.1436 7.16343 9.91777 6.6182 9.51577 6.2162C9.11377 5.8142 8.56854 5.58836 8.00003 5.58836C7.43151 5.58836 6.88629 5.8142 6.48429 6.2162C6.08229 6.6182 5.85645 7.16343 5.85645 7.73195C5.85645 8.30046 6.08229 8.84569 6.48429 9.24769C6.88629 9.64969 7.43151 9.87553 8.00003 9.87553Z", stroke: color || '#11245A', strokeWidth: "1.2", strokeLinecap: "round", strokeLinejoin: "round", fill: "none" })));
};

export { LocationPin as default };
